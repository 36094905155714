<template lang="pug">
  .p-0
    .content-loader-center.m-0.h-100(v-if='isLoadingProduct || isCreatingProduct || isUpdatingProduct')
      .text-center.flex-center
        .loading-bg-inner
           .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement du formulaire...
    div.details-container(v-else :key="key")
      div.d-flex.content-scrollable-sticky.h-100.justify-content-start
        #nav-sticky-vertical.text-center.h-100(ref="navStickyVertical" style='position: relative; background: #EEF1F6; overflow-x: hidden; width: 240px; min-width: 240px; max-width: 240px;')
          // Mettre ici la miniature du produit
          .close-btn.d-flex.justify-content-end.pt-1(style="padding-right: 0.8rem")
            span.material-icons-outlined(@click="closeMenuSidebar" style="cursor: pointer;")
              | chevron_left
          .head
            .d-flex.justify-content-between.px-1
              div
                h3.text-nowrap Produit : {{product.reference}}
            .d-flex.justify-content-between.pb-1.px-1
              .d-flex
                //- Status(:statusId="document.status" :nature="document.nature")
                span.text-nowrap(style="white-space: break-spaces;") {{product.label}}
              .end
          ul
            li(@click="tabActive = 'product'")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'product' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | corporate_fare
                span.pl-1.text-nowrap Fiche produit
            li(@click="tabActive = 'providers'" v-if="!(product.productType == 3 || product.productType == 4 || product.productType == 6) && checkFunctionUser(34, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'providers' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | assignment
                span.pl-1.text-nowrap Références fournisseurs
            li(v-if="!(product.productType == 5) && !(product.productType == 3 || product.productType == 4 || product.productType == 1 || product.productType == 6) && checkFunctionUser(34, 'all')" @click="tabActive = 'stocks'")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'stocks' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | euro_symbol
                span.pl-1.text-nowrap Stocks
      #content-wrapper.d-flex.flex-column.h-100(style="width: calc(100% - 240px)" ref="contentWrapper")
        .h-100.w-100
          validation-observer.h-100(ref='formProduct' #default='{ errors }')
            b-form.w-100.h-100(autocomplete='off' )
              .d-flex.flex-column.w-100.px-1.pt-1(:style="`height: calc(100% - 46px); overflow: auto`")
                .d-flex.w-100.mb-1.align-items-center.justify-content-between
                  .d-flex.content-header
                    h2.m-0.pr-1.mr-1(style="border-right: 1px solid #e2e2e2") {{ $route.meta.pageTitle }}
                    h3.m-0 {{ product.label }}
                  .content-header-actions.d-flex
                    feather-icon.cursor-pointer(icon='XIcon' size='24' @click='cancel()')
                #product.pt-0(v-show='tabActive == "product"')
                  validation-observer(ref='product')
                    .d-flex.w-100.my-0
                      .d-flex.flex-column.w-100.p-0.mb-1
                        .btn-group.w-100(role='group' aria-label='Basic example')
                          button.btn.justify-content-center(:class="product.productType == 0 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="product.productType = 0, product.unitId = 1") Fourniture
                          button.btn.justify-content-center(:class="product.productType == 5 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="product.productType = 5, product.unitId = 2") Main d'oeuvre
                          button.btn.justify-content-center(:class="product.productType == 3 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="product.productType = 3, product.unitId = 1, resetWorkElement()") Ouvrage
                          button.btn.justify-content-center(:class="product.productType == 1 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="product.productType = 1, product.unitId = 1") Sous-traitance
                          button.btn.justify-content-center(:class="product.productType == 2 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="product.productType = 2, product.unitId = 1") Matériel
                          button.btn.justify-content-center(:class="product.productType == 6 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="product.productType = 6, product.unitId = 1") Autres
                          button.btn.justify-content-center(:class="product.productType == 4 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="product.productType = 4, product.unitId = 1") Texte
                    .d-flex.w-100.my-0
                      .d-flex.flex-column.mb-1.mr-0.mr-md-1(v-if="product.productType != 4")
                        b-form-group(label="Référence de vente *")
                          validation-provider(#default='{ errors }' name='reference' rules="required")
                            b-form-input(v-model="product.reference" id="reference" :state="errors.length > 0 ? false : null")
                            small.text-danger {{ errors[0] }}
                      .d-flex.flex-column.mb-1.w-100
                        b-form-group(label="Libellé du produit")
                          validation-provider(#default='{ errors }' name='label')
                            b-form-input(v-model="product.label" id="label" :state="errors.length > 0 ? false : null")
                    .d-flex.w-100.align-items-center.mb-1(v-if="product.productType != 4")
                      b-form-checkbox.mr-1.p-0.pr-1#unAvailableForSale(v-model='product.unAvailableForSale')
                      label.m-0(for="unAvailableForSale") 
                        em Indisponible à la vente
                    .d-flex.w-100.my-0
                      .d-flex.w-100.flex-column.mb-1
                        b-form-group(label="Description *")
                          validation-provider(#default='{ errors }' name='description' rules="required")
                            quill-editor.editor.edit(v-model="product.description" :options="editorOption" :state="errors.length > 0 ? false : null" )
                            small.text-danger {{ errors[0] }}
                    .d-flex.w-100.my-0
                      .d-flex.w-100.flex-column.mb-1
                        small.cursor-pointer.text-primary(@click="displayMore = !displayMore")
                          | {{ (displayMore || product.reference2 != null || product.description2 != null) ? "- Masquer les informations supplémentaire" : "+ Afficher les informations supplémentaire" }}
                    .d-flex.w-100.my-0(v-if="displayMore || product.reference2 != null || product.description2 != null")
                      .d-flex.w-100.flex-column.mb-1
                        b-form-group(label="Référence supplémentaire")
                          b-form-input(v-model="product.reference2" id="reference2")
                    .d-flex.w-100.my-0(v-if="displayMore || product.reference2 != null || product.description2 != null")
                      .d-flex.w-100.flex-column.mb-1
                        b-form-group(label="Description supplémentaire")
                          quill-editor.editor.edit(v-model="product.description2" :options="editorOption")
                    .d-flex.w-100(v-if="product.productType != 4")
                      .d-flex.w-50.flex-column.mb-1.mr-1
                        b-form-group(label="Catégories d'article")
                          validation-provider(#default='{ errors }' name='productCategoriesId')
                            Treeselect.text-left.w-100(v-model="product.productCategoriesId" :multiple="true" :options="productCategoriesTreeList" value-consists-of="LEAF_PRIORITY" noOptionsText="Aucun résultat trouvé" noChildrenText="Aucun résultat trouvé" :show-count="true" placeholder="" search-nested)
                      .d-flex.w-50.flex-column
                        b-form-group(label="Catégorie de vente")
                          validation-provider(#default='{ errors }' name='saleCategoryId')
                            Treeselect.text-left.w-100(v-model="product.saleCategoryId" :multiple="false" :options="saleCategoriesTreeList" value-consists-of="LEAF_PRIORITY" noOptionsText="Aucun résultat trouvé" noChildrenText="Aucun résultat trouvé" :show-count="true" placeholder="" search-nested)
                    .d-flex.w-100(v-if="product.productType != 4")
                      .d-flex.w-25.flex-column.mb-1.mr-1
                        b-form-group(label="Quantité par défaut *" rules="required")
                          validation-provider(#default='{ errors }' name='quantity')
                            b-form-input(v-model="product.quantity" :step="0.1" :min="0" id="quantity" type="number")
                            small.text-danger {{ errors[0] }}
                      .d-flex.w-25.flex-column.mb-1.mr-1
                        b-form-group(label="Unité *")
                          validation-provider(#default='{ errors }' name='unit' rules="required")
                            v-select(v-model="product.unitId" :reduce="(el) => el.value" :options="unitOptions" :state="errors.length > 0 ? false : null")
                            small.text-danger {{ errors[0] }}
                      .d-flex.w-25.flex-column.mb-1.mr-1
                        b-form-group(label="Conditionnement *" rules="required")
                          validation-provider(#default='{ errors }' name='packagingQuantity')
                            b-form-input(v-model="product.packagingQuantity" :step="0.1" :min="0" id="packagingQuantity" type="number")
                            small.text-danger {{ errors[0] }}
                      .d-flex.w-25.flex-column
                        b-form-group(label="Eco-contribution" rules="required")
                          validation-provider(#default='{ errors }' name='ecoContribution')
                            b-form-input(v-model="product.ecoContribution" :step="0.1" :min="0" id="ecoContribution" type="number")
                            small.text-danger {{ errors[0] }}
                    .d-flex.w-100(v-if="product.productType == 3")
                      .d-flex.w-100.flex-column.mb-1
                        vs-divider.m-0.mt-1.mb-50.text-primary(color="#cccccc" position="center")
                          span.text-primary Composants de l'ouvrage
                        ProductWorkElements(:product="product" v-if="product.productType == 3")
                        vs-divider.m-0.mt-1.mb-50(color="#cccccc")
                    .d-flex.w-100.my-0.d-flex.justify-content-between(v-if="product.productType != 4")
                      div.d-flex.align-items-center
                        h3.text-primary {{ `Tarif / remise` }}
                        b-form-checkbox.ml-1#useDegressiveSaleRate(v-if="product.productType != 3" v-model='product.useDegressiveSaleRate' @change="actionDegressiveSaleRateArray")
                        label.m-0(v-if="product.productType != 3" for="useDegressiveSaleRate") 
                          em Appliquer un tarif dégressif
                      b-col.py-0.mb-1(v-if="!checkFunctionUser(34, 'all') && product.useDegressiveSaleRate" cols="3")
                        b-form-group
                          validation-provider(#default='{ errors }' name='dryDisbursed')
                            b-input-group(prepend='Déboursé sec HT')
                              b-form-input(v-model="product.dryDisbursed" @blur="changeMarginWithoutPurchaseModule" type="number" id="dryDisbursed" :state="errors.length > 0 ? false : null")
                    div(v-if="!product.useDegressiveSaleRate")
                      .d-flex.w-100
                        .d-flex.w-100.flex-column.mb-1.mr-1(v-if="product.productType != 4")
                          b-form-group(label="Prix unitaire HT *")
                            validation-provider(#default='{ errors }' name='priceHT' rules="required")
                              b-form-input(v-model="product.priceHT" @blur="checkFunctionUser(34, 'all') ? changeMargin() : changeMarginWithoutPurchaseModule()" :step="0.1" :min="0" id="priceHT" type="number" :state="errors.length > 0 ? false : null" :disabled="product.productType == 3")
                              small.text-danger {{ errors[0] }}
                        .d-flex.w-100.flex-column.mb-1.mr-1(cols="4" v-if="product.productType != 4")
                          b-form-group(label="Remise par défaut (%)")
                            validation-provider(#default='{ errors }' name='discount')
                              b-form-input(v-model="product.discount" @blur="checkFunctionUser(34, 'all') ? changeMargin() : changeMarginWithoutPurchaseModule()" :step="0.1" :min="0" id="discount" type="number" :state="errors.length > 0 ? false : null")
                        .d-flex.w-100.flex-column.mb-1(cols="4" v-if="product.productType != 4")
                          b-form-group(label="TVA *")
                            validation-provider(#default='{ errors }' name='referencielTvaId' rules="required")
                              v-select.w-100(:loading="isLoadingReferencielTVAsList" :clearable="false" :closeOnSelect="true" v-model="product.referencielTvaId" :options="referencielTvasInstitution" :reduce="(el) => el.id")
                                template(v-slot:no-options='')
                                  template  Aucun r&eacute;sultat trouv&eacute;
                              small.text-danger {{ errors[0] }}
                      .d-flex.w-100(v-if="!checkFunctionUser(34, 'all') && product.productType != 4")
                        .d-flex.w-100.flex-column.mb-1.mr-1(cols="4")
                          b-form-group(label="Déboursé sec HT")
                            validation-provider(#default='{ errors }' name='dryDisbursed')
                              b-form-input(v-model="product.dryDisbursed" @blur="changeMarginWithoutPurchaseModule" :step="0.1" :min="0" id="dryDisbursed" type="number" :state="errors.length > 0 ? false : null" :disabled="product.productType == 3")
                        .d-flex.w-100.flex-column.mb-1.mr-1(cols="8")
                          b-form-group(label="Marge brute unitaire")
                            .false-input.disabled
                              .false-input-content
                                span.text-primary.false-input-sub-content.fi-border-r {{ formatPercent(product.marginRate) }}
                                span.text-primary.false-input-sub-content {{ formatCurrency(product.grossMarginHT) }}  
                    .d-flex.align-items-end(v-else v-for="(elementDegressiveSaleRate, indexDegressiveSaleRate) of product.degressiveSalesRates" :key="indexDegressiveSaleRate")
                      div(style="flex:1")
                        b-row.my-0
                          b-col.py-0.mb-1.d-flex.flex-column
                            label.w-100.text-left(label-for='Quantity' v-if="indexDegressiveSaleRate == 0" style="text-align-last:center") Quantité *
                            b-row.align-items-center(style="gap:5px; padding-left:12px;" v-if="indexDegressiveSaleRate != product.degressiveSalesRates.length - 1")
                              span Entre
                              b-form-input#quantityMin.not-autocomplete.d-flex(disabled type="number" v-model='elementDegressiveSaleRate.quantityMin' autocomplete='nope' aria-autocomplete='nope' style="flex:1")
                              span et
                              b-form-input#quantityMax.not-autocomplete.d-flex(type="number" v-model='elementDegressiveSaleRate.quantityMax' autocomplete='nope' aria-autocomplete='nope' style="flex:1" @blur="changeQuantityMax(indexDegressiveSaleRate)")
                            b-row.align-items-center(v-else)
                              span.font-weight-bold.p-1.w-100.text-center {{ elementDegressiveSaleRate.quantityMin }} ou plus
                          b-col.py-0.mb-1.d-flex.flex-column
                            label.w-100.text-left(label-for='price' v-if="indexDegressiveSaleRate == 0") Prix unitaire HT *
                            b-form-group.w-100
                              validation-provider(#default='{ errors }' name='price' rules="required")
                                b-form-input#price.w-100.not-autocomplete(type="number" @blur="checkFunctionUser(34, 'all') ? changeMargin() : changeMarginWithoutPurchaseModule(indexDegressiveSaleRate)" v-model='elementDegressiveSaleRate.price' autocomplete='nope' aria-autocomplete='nope')
                                small.text-danger {{ errors[0] }}
                          b-col.py-0.mb-1.d-flex.flex-column
                            label.w-100.text-left(label-for='discount' v-if="indexDegressiveSaleRate == 0") Remise par défaut (%)
                            b-form-group.w-100
                              b-form-input#discount.w-100.not-autocomplete(type="number" @blur="checkFunctionUser(34, 'all') ? changeMargin() : changeMarginWithoutPurchaseModule(indexDegressiveSaleRate)" v-model='elementDegressiveSaleRate.discount' autocomplete='nope' aria-autocomplete='nope')
                          b-col.py-0.mb-1(v-if="!checkFunctionUser(34, 'all')")
                            label.w-100.text-left(v-if="indexDegressiveSaleRate == 0") Marge brute unitaire
                            b-form-group
                              .false-input.disabled
                                .false-input-content
                                  span.text-primary.false-input-sub-content.fi-border-r {{ formatPercent(elementDegressiveSaleRate.marginRate) }}
                                  span.text-primary.false-input-sub-content {{ formatCurrency(elementDegressiveSaleRate.grossMarginHT) }}  
                      div.py-0.mb-1(v-if="product.degressiveSalesRates.length > 2")
                        b-button(variant="outline-danger" @click="deleteDegressiveSaleRateLocal(indexDegressiveSaleRate, elementDegressiveSaleRate)") 
                          feather-icon.m-0(icon="TrashIcon")
                    div.mb-1(v-if="product.useDegressiveSaleRate" style="padding-left:12px;")
                      small(@click="addDegressiveSaleRate").cursor-pointer.text-primary + Ajouter une ligne
                    h3.text-primary {{ `Notes supplémentaires` }}
                    .d-flex.w-100.my-0
                      .d-flex.w-100.flex-column.mb-1
                        b-form-group
                          validation-provider(#default='{ errors }' name='internalNote')
                            quill-editor.editor.edit(v-model="product.internalNote" :options="editorOption" :state="errors.length > 0 ? false : null" )
                            small.text-danger {{ errors[0] }}
                    //- b-row.my-0
                    //-   b-col.py-0.mb-1(cols="12")
                    //-     h3.mb-1.text-primary Fichiers liés
                    //- b-row.my-0 
                    //-   b-col.py-0.mb-1(cols="12")
                    //-     vue-dropzone(ref="imgDropZone" id="customdropzone" class="border-primary w-100" :options="dropzoneOptions" @vdropzone-complete="uploadFileCompleted" @vdropzone-file-added="uploadFileAdded")
                #providers.pt-0(v-show='tabActive == "providers"' style="overflow-y: scroll;overflow-x: hidden; height:100%")
                  validation-observer(ref='provider')
                    b-row.my-0
                        b-col.py-0.mb-1(cols="12" :class="product.productProviders && product.productProviders.length > 0 ? 'mt-1' : ''")
                          b-form-group
                            validation-provider(#default='{ errors }' name='productProvidersSelect')
                              v-select.w-100#productProvidersSelect(ref="productProvidersSelect" :loading='isLoadingProductProvidersList' @input='bindProductProvider' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='providersOptions' placeholder='Rechercher un produit fournisseur' :filter="fuseSearch")
                                template(#option='{ reference, label, company, description }')
                                  div
                                    span(style='font-size:16px;white-space:normal') Référence : {{ reference }}{{ label ? ' - ' + label : '' }}{{ company.name ? ' - Fournisseur : ' + company.name : '' }}
                                    br
                                    div(style='white-space:normal')
                                      span.w-100(style='color:#a2a3a8; font-size:13px' v-html="description")
                                template(v-slot:no-options='')
                                  template  Aucun r&eacute;sultat trouv&eacute;
                                li.border-bottom.p-1.py-50(slot='list-header')
                                  b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newProductProvider' variant='primary' size='sm')
                                    | + Nouveau produit fournisseur
                              //- ejs-dropdownlist.w-100(@input.prevent='bindProductProvider' :dataSource='providersOptions' :itemTemplate="tproductProvidersSelectTemplate" :fields="{ text: 'label', value: 'id' }" :headerTemplate="'headerTemplate'" :placeholder="'Rechercher un produit fournisseur'")
                              //-   template(v-slot:headerTemplate)
                              //-     div.p-50
                              //-       ejs-button(v-on:click.native="newProductProvider" :content="'+ Nouveau produit fournisseur'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" cssClass="w-100")
                    div(v-for="(elementProductProviders, indexProductProviders) in product.productProviders" :key="indexProductProviders" )
                      vs-divider.m-0.mt-1.mb-50.text-primary(color="#cccccc" position="left")
                        h3.text-primary Fournisseur {{ indexProductProviders + 1 }}
                      .d-flex 
                        b-row.my-0.flex
                          b-col.py-0.mb-1(cols="12")
                            .btn-group.w-100(role='group' aria-label='Basic example')
                              button.btn.justify-content-center(:class="elementProductProviders.productType == 0 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="elementProductProviders.productType = 0, product.unitId = 1") Fourniture
                              button.btn.justify-content-center(:class="elementProductProviders.productType == 5 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="elementProductProviders.productType = 5, elementProductProviders.unitId = 2") Main d'oeuvre
                              button.btn.justify-content-center(:class="elementProductProviders.productType == 2 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="elementProductProviders.productType = 2, product.unitId = 1") Matériel
                              button.btn.justify-content-center(:class="elementProductProviders.productType == 6 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="elementProductProviders.productType = 6, product.unitId = 1") Autres
                        b-button-group.py-0.mb-1(style="margin-right:7px; align-self: flex-end;")
                          b-button.btn-icon(variant='outline-primary' @click="transfertDataToSaleProduct(elementProductProviders)")
                            feather-icon(icon='SkipBackIcon')
                          b-button(variant="outline-danger" @click="deleteProductProviderLocal(elementProductProviders, indexProductProviders)")
                            feather-icon.m-0(icon="TrashIcon")
                      b-row.my-0
                        b-col.py-0(cols="12" v-if="elementProductProviders.id")
                          h5.text-primary.d-flex.justify-content-between
                            span(style='min-width: fit-content; margin-right:10px;')
                              feather-icon(icon='ChevronRightIcon')
                              | {{ elementProductProviders.company.name }}
                        b-col.py-0.mb-1(cols="12" v-else)
                          b-form-group(label="Fournisseur *")
                            validation-provider(#default='{ errors }' name='selectCompany' rules="required")
                              v-select.w-100#company(ref="selectCompany" :reduce="(el) => el.value" v-model="elementProductProviders.companyId" :loading='isLoadingCompaniesList' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='companyOptions' placeholder='Rechercher un fournisseur'  :state="errors.length > 0 ? false : null")
                                template(v-slot:no-options='')
                                  template  Aucun r&eacute;sultat trouv&eacute;
                                li.border-bottom.p-1.py-50(slot='list-header')
                                  b-button.w-100.d-flex.justify-content-center.align-items-center(@click="newProvider" variant='primary' size='sm')
                                    | + Nouveau fournisseur
                                    span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                                      | open_in_new
                              small.text-danger {{ errors[0] }}
                      b-row.my-0
                        b-col.py-0.mb-1(cols="3")
                          b-form-group(label="Référence d'achat *")
                            validation-provider(#default='{ errors }' name='reference' rules="required")
                              b-form-input(v-model="elementProductProviders.reference" id="reference" :state="errors.length > 0 ? false : null")
                              small.text-danger {{ errors[0] }}
                        b-col.py-0.mb-1(cols='12' md="9")
                          b-form-group(label="Libellé fournisseur")
                            validation-provider(#default='{ errors }' name='label')
                              b-form-input(v-model="elementProductProviders.label" id="label" :state="errors.length > 0 ? false : null")
                      b-row.my-0
                        b-col.py-0.mb-1(cols="12")
                          b-form-group(label="Description *")
                            validation-provider(#default='{ errors }' name='description' rules="required")
                              quill-editor.quill-editor-scroll.editor.edit(v-model="elementProductProviders.description" :options="editorOption" :state="errors.length > 0 ? false : null")
                              small.text-danger {{ errors[0] }}
                      b-row.my-0(v-if="product.productType != 4")
                        b-col.py-0.mb-1(cols="3")
                          b-form-group(label="Catégorie d'achat")
                            validation-provider(#default='{ errors }' name='purchaseCategoryId')
                              Treeselect.text-left.w-100(v-model="elementProductProviders.purchaseCategoryId" :multiple="false" :options="purchaseCategoriesTreeList" value-consists-of="LEAF_PRIORITY" noOptionsText="Aucun résultat trouvé" noChildrenText="Aucun résultat trouvé" :show-count="true" placeholder="" search-nested)
                        b-col.py-0.mb-1(cols="3")
                          b-form-group(label="Quantité par défaut *")
                            validation-provider(#default='{ errors }' name='quantity' rules="required")
                              b-form-input(v-model="elementProductProviders.quantity" :step="0.1" :min="0" id="quantity" type="number")
                              small.text-danger {{ errors[0] }}
                        b-col.py-0.mb-1(cols="3")
                          b-form-group(label="Unité *")
                            validation-provider(#default='{ errors }' name='unit' rules="required")
                              v-select(v-model="elementProductProviders.unitId" :reduce="(el) => el.value" :options="unitOptions" :state="errors.length > 0 ? false : null")
                              small.text-danger {{ errors[0] }}
                        b-col.py-0.mb-1(cols="3")
                          b-form-group(label="Eco-contribution")
                            validation-provider(#default='{ errors }' name='ecoContribution')
                              b-form-input(v-model="elementProductProviders.ecoContribution" :step="0.1" :min="0" id="ecoContribution" type="number")
                      div.d-flex.justify-content-between(v-if="product.productType != 4" style="padding: 0 7px")
                        div.d-flex.align-items-center
                          h5.text-primary {{ `Tarif achat / remise` }}
                          b-form-checkbox.ml-1(v-model='elementProductProviders.useDegressivePurchaseRate' :id="'useDegressivePurchaseRate-'+indexProductProviders" @change="actiondegressivePurchaseRateArray(elementProductProviders.useDegressivePurchaseRate, indexProductProviders)")
                          label.mr-0(:for="'useDegressivePurchaseRate-'+indexProductProviders")
                            em Appliquer un tarif dégressif
                      b-row.my-0(v-if="!elementProductProviders.useDegressivePurchaseRate")
                        b-col.py-0.mb-1
                          b-form-group(label="Prix d'achat HT *")
                            validation-provider(#default='{ errors }' name='netPrice' rules="required")
                              b-form-input(v-model="elementProductProviders.netPrice" @blur="changeMargin" :step="0.01" :min="0" id="netPrice" type="number")
                              small.text-danger {{ errors[0] }}
                        b-col.py-0.mb-1
                          b-form-group(label="Remise par défaut (%)")
                            validation-provider(#default='{ errors }' name='discount')
                              b-form-input(v-model="elementProductProviders.discount" @blur="changeMargin" :step="0.01" :min="0" id="discount" type="number")
                        b-col.py-0.mb-1(v-if="!product.useDegressiveSaleRate")
                          b-form-group(label="Marge brute unitaire")
                            .false-input.disabled
                              .false-input-content
                                span.text-primary.false-input-sub-content.fi-border-r {{ formatPercent(elementProductProviders.marginRate) }}
                                span.text-primary.false-input-sub-content {{ formatCurrency(elementProductProviders.grossMarginHT) }}
                      .d-flex.align-items-end(v-else v-for="(elementDegressivePurchaseRate, indexDegressivePurchaseRate) of elementProductProviders.degressivePurchaseRates" :key="indexDegressivePurchaseRate")
                        div(style="flex:1")
                          b-row.my-0 
                            b-col.py-0.mb-1.d-flex.flex-column
                              label.w-100.text-left(label-for='Quantity' v-if="indexDegressivePurchaseRate == 0" style="text-align-last:center") Quantité *
                              b-row.align-items-center(style="gap:5px; padding-left:12px;" v-if="indexDegressivePurchaseRate != elementProductProviders.degressivePurchaseRates.length - 1")
                                span Entre
                                b-form-input#quantityMin.not-autocomplete.d-flex(disabled type="number" v-model='elementDegressivePurchaseRate.quantityMin' autocomplete='nope' aria-autocomplete='nope' style="flex:1")
                                span et
                                b-form-input#quantityMax.not-autocomplete.d-flex(type="number" v-model='elementDegressivePurchaseRate.quantityMax' autocomplete='nope' aria-autocomplete='nope' style="flex:1" @blur="changeQuantityMaxProvider(indexProductProviders, indexDegressivePurchaseRate)")
                              b-row.align-items-center(v-else)
                                span.font-weight-bold.p-1.w-100.text-center {{ elementDegressivePurchaseRate.quantityMin }} ou plus
                            b-col.py-0.mb-1.d-flex.flex-column
                              label.w-100.text-left(label-for='price' v-if="indexDegressivePurchaseRate == 0") Prix unitaire HT *
                              b-form-group.w-100
                                validation-provider(#default='{ errors }' name='price' rules="required")
                                  b-form-input#price.w-100.not-autocomplete(type="number" @blur="changeMarginByDegressivePurchaseRate(indexProductProviders, indexDegressivePurchaseRate)" v-model='elementDegressivePurchaseRate.price' autocomplete='nope' aria-autocomplete='nope')
                                  small.text-danger {{ errors[0] }}
                            b-col.py-0.mb-1.d-flex.flex-column
                              label.w-100.text-left(label-for='discount' v-if="indexDegressivePurchaseRate == 0") Remise par défaut (%)
                              b-form-group.w-100
                                b-form-input#discount.w-100.not-autocomplete(type="number" @blur="changeMarginByDegressivePurchaseRate(indexProductProviders, indexDegressivePurchaseRate)" v-model='elementDegressivePurchaseRate.discount' autocomplete='nope' aria-autocomplete='nope')
                            b-col.py-0.mb-1(v-if="!product.useDegressiveSaleRate")
                              label.w-100.text-right(v-if="indexDegressivePurchaseRate == 0") Marge brute unitaire
                              b-form-group.w-100
                                .false-input.disabled
                                  .false-input-content
                                    span.text-primary.false-input-sub-content.fi-border-r {{ formatPercent(elementDegressivePurchaseRate.marginRate) }}
                                    span.text-primary.false-input-sub-content {{ formatCurrency(elementDegressivePurchaseRate.grossMarginHT) }}
                        div.py-0.mb-1(v-if="elementProductProviders.degressivePurchaseRates.length > 2")
                          b-button(variant="outline-danger" @click="deleteDegressivePurchaseRateLocal(indexProductProviders, indexDegressivePurchaseRate, elementDegressivePurchaseRate)") 
                            feather-icon.m-0(icon="TrashIcon")
                      div.mb-1(v-if="elementProductProviders.useDegressivePurchaseRate" style="padding-left:12px;")
                        small(@click="addDegressivePurchaseRate(indexProductProviders)").cursor-pointer.text-primary + Ajouter une ligne
                #stocks.pt-0(v-show='tabActive == "stocks"')
                  validation-observer(ref='stock')
                    b-row.my-0
                      b-col.py-0.mb-1(cols="6" :class="")
                        b-form-group(label="Alerte stock min")
                          validation-provider(#default='{ errors }' name='alertQuantityMin')
                            b-form-input(type="number" v-model="product.alertQuantityMin" id="alertQuantityMin" :state="errors.length > 0 ? false : null")
                      b-col.py-0.mb-1(cols="6")
                        b-form-group(label="Alerte stock max")
                          validation-provider(#default='{ errors }' name='alertQuantityMax')
                            b-form-input(type="number" v-model="product.alertQuantityMax" id="alertQuantityMax" :state="errors.length > 0 ? false : null")
                    b-row.my-0
                      b-col.py-0.mb-1(cols="6")
                        b-form-group(label="Mode de destockage")
                          validation-provider(#default='{ errors }' name='destockingType')
                            v-select(disabled v-model="product.destockingType" :reduce="(el) => el.value" :options="destockingTypeOptions" :state="errors.length > 0 ? false : null")
                      b-col.py-0.mb-1(cols="6")
                        b-form-group(label="Mode de restockage")
                          validation-provider(#default='{ errors }' name='restockingType')
                            v-select(disabled v-model="product.restockingType" :reduce="(el) => el.value" :options="restockingTypeOptions" :state="errors.length > 0 ? false : null")
                    b-row.my-0
                      div.d-flex.justify-content-between.w-100(style="padding: 0 12px")
                        h3.mb-1.text-primary Gestion de stock
                        div.d-flex.align-items-center
                          span.rounded.px-50.bg-primary.text-white
                            | {{ totalStockProduct }}
                    b-row.my-0
                      b-table.w-100(responsive="sm" :items="product.stocks" :fields="fields")
              b-row.my-0.justify-content-center
                b-col.py-0(cols='12')
                  .d-flex.justify-content-between(style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem;')
                    b-button(variant='outline-primary' @click='cancel()')
                      feather-icon(icon='ArrowLeftIcon')
                        | Annuler
                    ejs-tooltip.tooltipcontainer(opensOn='Custom' v-on:mouseover.native='customOpen' v-on:mouseleave.native='customClose' content="Des références ou descriptions sont manquantes" :cssClass="cssClass" ref='tooltip')
                      b-button#buttonSubmit(variant='primary' @click='addProduct' :disabled="referenceOfNewProductProviderWorkElementAlreadyExists() || AllWorkElementHaveRef()")
                        feather-icon(icon='SaveIcon' v-if='product.id')
                        feather-icon(icon='PlusIcon' v-else)
                        | {{ product.id ? &apos;Modifier&apos; : &apos;Créer&apos; }}
</template>

<script>
import ProductWorkElements from "@/components/catalog/formProduct/ProductWorkElements.vue";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { ProductDefaultProperties } from "@/types/api-orisis/models/ProductModel";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { required } from "@validations";
import {
  BButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BTable,
} from "bootstrap-vue";
import Fuse from "fuse.js";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import Fuse from "fuse.js";
import { mapActions, mapGetters } from "vuex";
import { checkFunctionUser } from "@/auth/utils.ts";
import ProductProvidersSelectTemplate from "@/views/catalog/ProductProvidersSelectTemplate.vue";

import {
  ValidationObserver,
  ValidationProvider,
  configure,
  localize,
} from "vee-validate";
configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  props: {
    id: {
      default: null,
    },
  },
  data() {
    return {
      displayMore: false,
      key: 0,
      cssClass: "tool-tip-error",
      tabActive: "product",
      dataOrigine: {},
      ProductDefaultProperties,
      product: {},
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      dropzoneOptions: {
        autoQueue: false,
        url: "https://httpbin.org/post",
        maxFilesize: 10000000,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: false,
        dictDefaultMessage: `<i class='fa fa-cloud-upload'></i><p class='text-default'>Cliquez pour modifier ou glissez-déposez une nouvelle image</p>`,
      },
      required,
      fields: [
        {
          key: "storageLocation.label",
          label: "Point de stockage",
          sortable: true,
        },
        { key: "quantity", label: "Stock", sortable: true },
      ],
      destockingTypeOptions: [
        { value: 1, label: `A partir d'un bon de livraison` },
      ],
      restockingTypeOptions: [
        { value: 1, label: `A partir d'un bon de réception` },
      ],
      tproductProvidersSelectTemplate() {
        return {
          template: ProductProvidersSelectTemplate,
        };
      },
    };
  },
  components: {
    ProductProvidersSelectTemplate,
  },
  computed: {
    ...mapGetters([
      "isLoadingProduct",
      "productCategoryTree",
      "unitsList",
      "workspaceSelected",
      "productCategoriesTreeList",
      "institutionSettingsActive",
      "isLoadingReferencielTVAsList",
      "isLoadingCompaniesList",
      "isLoadingProductProvidersList",
      "saleCategoriesTreeList",
      "purchaseCategoriesTreeList",
      "isCreatingProduct",
      "isUpdatingProduct",
      "workElementValidate",
      "getWorkElementDetails",
    ]),
    workElementHeader: {
      get() {
        return this.$store.getters.getWorkElementHeader;
      },
    },
    referencielTvasInstitution() {
      return this.institutionSettingsActive.referencielTvas.map(
        (referencielTva) => {
          return referencielTva.referencielTva;
        }
      );
    },
    providersOptions() {
      return this.$store.getters.productProvidersList.filter(
        (e) => e.productId == null
      );
    },
    companyOptions() {
      return this.$store.getters.companiesList
        .filter((company) => company.companyType.id == 4)
        .map((elem) => {
          return {
            label: elem.name,
            value: elem.id,
          };
        });
    },
    unitOptions() {
      return this.$store.getters.unitsList.map((elem) => {
        return {
          label: elem.label,
          value: elem.id,
        };
      });
    },
    marginNegativeStyle() {
      if (this.product.priceHT - this.product.dryDisbursed < 0) {
        return "color:red !important; border-color:red !important";
      } else {
        return true;
      }
    },
    totalStockProduct() {
      let total = 0;
      this.product?.stocks?.forEach((element) => {
        total += element.quantity;
      });
      return total > 1 ? `${total} produits` : `${total} produit`;
    },
  },
  async created() {
    await this.productEvent();
    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({}).then((res) => {
        this.workElementHeader.unitId.choice = res;
      });
    } else {
      this.workElementHeader.unitId.choice = this.unitsList;
    }
  },
  mounted() {
    this.getProductCategoriesTree({});
    this.getSaleCategoriesTree({});
    if (this.checkFunctionUser(74, "all")) {
      this.getPurchaseCategoriesTree({});
    }
  },
  methods: {
    ...mapActions([
      "getProductCategoriesTree",
      "getUnits",
      "getProductById",
      "getProductProviders",
      "getCompanies",
      "getSaleCategoriesTree",
      "getPurchaseCategoriesTree",
      "deleteProductProviders",
      "deleteDegressiveSaleRates",
      "deleteDegressivePurchaseRates",
      "updateProductProvider",
      "updateProductSubProduct",
      "getProducts",
    ]),
    checkFunctionUser,
    customOpen(e) {
      const buttonSubmit = document.getElementById("buttonSubmit");
      if (buttonSubmit.disabled) this.$refs.tooltip.open(e.target);
    },
    customClose() {
      const buttonSubmit = document.getElementById("buttonSubmit");
      if (buttonSubmit.disabled) this.$refs.tooltip.close();
    },
    formatNumber,
    formatCurrency,
    formatPercent,
    closeMenuSidebar() {
      this.$refs.navStickyVertical.classList.toggle("sidebar-closed");
      this.$refs.contentWrapper.classList.toggle("sidebar-is-closed");
    },
    transfertDataToSaleProduct(productProvider) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous êtes sur le point de remplacer les données du produit de vente avec les données du produit d'achat de vente. Êtes-vous sûr de vouloir remplacer les données ?",
          {
            title: "Transférer les données du produit d'achat ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.product.reference = productProvider.reference;
            this.product.reference2 = productProvider.reference2;
            this.product.label = productProvider.label;
            this.product.description = productProvider.description;
            this.product.description2 = productProvider.description2;
            this.product.internalNote = productProvider.internalNote;
            this.product.unitId = productProvider.unitId;
            this.product.quantity = productProvider.quantity;
            this.product.priceHT = productProvider.netPrice;
            this.product.discount = productProvider.discount;
            this.product.useDegressiveSaleRate =
              productProvider.useDegressivePurchaseRate;
            this.product.degressiveSalesRates =
              productProvider.degressivePurchaseRates;
            this.product.ecoContribution = productProvider.ecoContribution;
            this.product.productType = productProvider.productType;
          }
        });
    },
    resetWorkElement() {
      this.$store.commit("SET_WORK_ELEMENT_DETAILS", []);
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["reference", "company.name", "decription", "label"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    referenceOfNewProductProviderAlreadyExists() {
      return this.product.productProviders
        .filter((elem) => elem.id == 0)
        .find((productProvider) =>
          this.$store.getters.productProvidersList
            .map((elem) => elem.reference)
            .includes(productProvider.reference)
        );
    },
    referenceOfNewProductProviderWorkElementAlreadyExists() {
      for (let element of this.getWorkElementDetails) {
        if (
          this.$store.getters.productProvidersList.some(
            (item) => element.reference === item.reference
          )
        ) {
          return true; // Une référence en double est trouvée
        }
      }
    },
    AllWorkElementHaveRef() {
      for (let element of this.getWorkElementDetails) {
        if (!element.reference) {
          return true;
        }
      }
      return false;
    },
    netPrice(i) {
      let netPrice =
        Math.round(
          this.product.productReferences[i].publicPrice *
            (1 - this.product.productReferences[i].discount / 100) *
            100
        ) / 100;
      this.product.productReferences[i].netPrice = netPrice;
      if (this.product.productReferences[i].isDefault)
        this.product.dryDisbursed = netPrice;
      return netPrice;
    },
    changeMarginWithoutPurchaseModule(index = 0) {
      if (this.product.useDegressiveSaleRate) {
        for (let i = index; i < this.product.degressiveSalesRates.length; i++) {
          const degressiveSaleRate = this.product.degressiveSalesRates[i];
          degressiveSaleRate.grossMarginHT = formatNumber(
            degressiveSaleRate.price * (1 - degressiveSaleRate.discount / 100) -
              this.product.dryDisbursed
          );
          degressiveSaleRate.marginRate = formatNumber(
            (degressiveSaleRate.grossMarginHT / degressiveSaleRate.price) * 100
          );
        }
      } else {
        this.product.grossMarginHT = formatNumber(
          this.product.priceHT * (1 - this.product.discount / 100) -
            this.product.dryDisbursed
        );
        this.product.marginRate =
          this.product.priceHT == 0
            ? 0
            : formatNumber(
                (this.product.grossMarginHT / this.product.priceHT) * 100
              );
      }
    },
    changeMargin() {
      if (
        this.product.productType == 0 ||
        this.product.productType == 2 ||
        this.product.productType == 5
      ) {
        for (let i = 0; i < this.product.productProviders.length; i++) {
          const productProvider = this.product.productProviders[i];
          if (productProvider.useDegressivePurchaseRate) {
            for (
              let j = 0;
              j < productProvider.degressivePurchaseRates.length;
              j++
            ) {
              const degressivePurchaseRate =
                productProvider.degressivePurchaseRates[j];
              degressivePurchaseRate.grossMarginHT = formatNumber(
                (this.product.useDegressiveSaleRate
                  ? this.product.degressiveSalesRates[0].price *
                    (1 - this.product.degressiveSalesRates[0].discount / 100)
                  : this.product.priceHT * (1 - this.product.discount / 100)) -
                  degressivePurchaseRate.price *
                    (1 - degressivePurchaseRate.discount / 100)
              );
              degressivePurchaseRate.marginRate = formatNumber(
                (degressivePurchaseRate.grossMarginHT /
                  (this.product.useDegressiveSaleRate
                    ? this.product.degressiveSalesRates[0].price
                    : this.product.priceHT)) *
                  100
              );
            }
          } else {
            productProvider.grossMarginHT = formatNumber(
              this.product.priceHT * (1 - this.product.discount / 100) -
                productProvider.netPrice * (1 - productProvider.discount / 100)
            );
            productProvider.marginRate =
              this.product.priceHT == 0
                ? 0
                : formatNumber(
                    (productProvider.grossMarginHT / this.product.priceHT) * 100
                  );
          }
        }
      }
    },
    changeMarginByDegressivePurchaseRate(
      productProviderIndex,
      degressivePurchaseRateIndex
    ) {
      const productProvider =
        this.product.productProviders[productProviderIndex];
      const degressivePurchaseRate =
        productProvider.degressivePurchaseRates[degressivePurchaseRateIndex];
      degressivePurchaseRate.grossMarginHT = formatNumber(
        (this.product.useDegressiveSaleRate
          ? this.product.degressiveSalesRates[0].price
          : this.product.priceHT) -
          degressivePurchaseRate.price -
          degressivePurchaseRate.price * (degressivePurchaseRate.discount / 100)
      );
      degressivePurchaseRate.marginRate = formatNumber(
        (degressivePurchaseRate.grossMarginHT /
          (this.product.useDegressiveSaleRate
            ? this.product.degressiveSalesRates[0].price
            : this.product.priceHT)) *
          100
      );
    },
    async productEvent() {
      if (!this.unitsList || this.unitsList.length == 0) {
        this.getUnits({});
      }
      this.getCompanies({ companyTypeId: 4 });
      if (
        this.checkFunctionUser(74, "all") &&
        this.checkFunctionUser(34, "all")
      ) {
        this.getProductProviders({});
      }
      if (this.id != "" && this.id !== null && this.id != 0) {
        this.getProductById({ productId: this.id }).then((res) => {
          this.product = res;
          this.dataOrigine = structuredClone(this.product);
          if (this.product.productType === 3) {
            this.$swal({
              title:
                "Vous vous appretez à modifier votre ouvrage, lors de la modification de celui-ci, les composants d'ouvrage seront mise à jour dans votre catalogue également",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "D'accord",
              customClass: {
                confirmButton: "d-none",
                cancelButton: "btn btn-primary ml-1",
              },
              buttonsStyling: false,
            });
          }
          if (this.product.parentId) {
            this.$swal({
              title:
                "Vous vous appretez à modifier un composant d'ouvrage, lors de la modification de celle-ci, l'ouvrage sera mis à jour dans votre catalogue également",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "D'accord",
              customClass: {
                confirmButton: "d-none",
                cancelButton: "btn btn-primary ml-1",
              },
              buttonsStyling: false,
            });
          }
        });
      } else {
        this.product = this.ProductDefaultProperties({});
      }

      this.getProducts({});
    },
    addProduct(e) {
      e.preventDefault();
      this.$refs.formProduct
        .validate()
        .then(async (success1) => {
          if (success1) {
            if (this.referenceOfNewProductProviderAlreadyExists()) {
              this.$bvToast.toast("La référence produit existe déjà.", {
                title:
                  `La référence ` +
                  this.referenceOfNewProductProviderAlreadyExists().reference +
                  ` ne peut pas être liée à un produit fournisseur car un produit avec cette référence existe déjà.`,
                variant: "danger",
                solid: true,
              });
            } else {
              if (this.product.id) {
                await this.$store
                  .dispatch("updateProduct", {
                    product: this.product,
                  })
                  .then(() => {
                    if (this.product.productType === 3)
                      this.updateProductSubProduct({
                        parentProductId: this.product.id,
                        productIds: this.getWorkElementDetails.reduce(
                          (acc, item) => {
                            acc[item.id] = item.quantity;
                            return acc;
                          },
                          {}
                        ),
                      });
                  });
              } else {
                await this.$store
                  .dispatch("createProduct", {
                    product: this.product,
                  })
                  .then((res) => {
                    if (this.product.productType === 3)
                      this.updateProductSubProduct({
                        parentProductId: res.id,
                        productIds: this.getWorkElementDetails.reduce(
                          (acc, item) => {
                            acc[item.id] = item.quantity;
                            return acc;
                          },
                          {}
                        ),
                      });
                  });
              }
              this.$nextTick(() => {
                this.$tabs.close({ to: "/catalog" });
              });
            }
          } else {
            Promise.all([
              this.$refs.product.validate(),
              this.$refs.provider.validate(),
              this.$refs.stock.validate(),
            ]).then((res) => {
              if (!res[0]) {
                this.tabActive = "product";
              } else if (!res[1]) {
                this.tabActive = "providers";
              } else if (!res[2]) {
                this.tabActive = "stocks";
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.product)) {
        if (
          this.$route.params.routeOrigine &&
          this.$route.params.routeOrigine !== ""
        ) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          this.$tabs.close();
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (
                this.$route.params.routeOrigine &&
                this.$route.params.routeOrigine !== ""
              ) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
    uploadFileAdded(file) {
      var dropzone = this.$refs.imgDropZone.dropzone;
      var _this = this;
      var reader = new FileReader();
      var base64;
      reader.onload = (fileLoadedEvent) => {
        base64 = fileLoadedEvent.target.result;
        // base64 is the dataURL
        file.dataURL = base64;
        dropzone.enqueueFile(file);
        return;
      };
      reader.readAsDataURL(file);
    },
    async uploadFileCompleted(upload) {
      try {
        let refresh = false;
        let file = upload;
        // let folderIds = []
        // let folderId = 0
        // if(this.folder=={} || !this.folder.id || (this.fromModule=='affair' && this.affair.folderId==null)){
        //   await this.createFolderAffair(this.affair).then(async(res) => {
        //     folderIds.push(res.folderId)
        //     folderId = res.folderId
        //     refresh = true
        //   })
        // }else{
        //   folderId = this.folder.id
        // }
        // if(refresh){
        //   await this.fetchFolder(folderId)
        // }
        // let formData = new FormData();
        // formData.append("id", 0)
        // formData.append("file", file)
        // formData.append("name", file.name)
        // formData.append("folderIds[]",folderId)
        // await this.createFile(formData)

        // AJOUTER L'IMAGE DU PRODUIT DANS LA GED => IL VA FALLOIR CREER UN DOSSIER PRODUITS ?
      } catch (error) {
        console.error(error);
      }
      this.$refs.imgDropZone.removeFile(upload);
    },
    newProductProvider() {
      this.product.productProviders.push({
        id: 0,
        label: this.product.label ? this.product.label : null,
        reference: null,
        referenceSupp: null,
        discount: 0,
        netPrice: 0,
        isDefault: false,
        companyId: null,
        productId: this.product.id,
        useDegressivePurchaseRate: false,
        degressivePurchaseRates: [],
        grossMarginHT: 0,
        marginRate: 0,
        ecoContribution: 0,
        purchaseCategoryId: null,
        description: this.product.description ? this.product.description : null,
        descriptionSupp: null,
        internalNote: null,
        unitId: this.product.unitId,
        quantity: 1,
        productType: this.product.productType,
      });
    },
    bindProductProvider(value) {
      console.lg(value);
      if (value) {
        this.product.productProviders.push({
          id: value.id,
          label: value.label,
          reference: value.reference,
          reference2: value.reference2,
          discount: value.discount,
          netPrice: value.netPrice,
          isDefault: false,
          companyId: value.companyId,
          company: value.company,
          productId: this.product.id,
          useDegressivePurchaseRate: value.useDegressivePurchaseRate,
          degressivePurchaseRates: value.degressivePurchaseRates,
          grossMarginHT: value.grossMarginHT,
          marginRate: value.marginRate,
          ecoContribution: value.ecoContribution,
          purchaseCategoryId: value.purchaseCategoryId,
          description: value.description,
          description2: value.descriptionSupp,
          internalNote: value.internalNote,
          unitId: value.unitId,
          quantity: value.quantity,
          productType: value.productType,
        });
        this.key++;
      }
    },
    deleteProductProviderLocal(productProvider, index) {
      if (productProvider.id) {
        this.$bvModal
          .msgBoxConfirm(
            "Ce produit fournisseur existe dans votre base de données des produits fournisseur. Si vous le supprimez, il disparaîtra de la liste des produits fournisseur. Si vous le déliez, il ne sera plus associé à ce produit de vente",
            {
              title:
                "Quelle action souhaitez-vous faire sur ce produit fournisseur ?",
              size: "sm",
              okVariant: "danger",
              okTitle: "Supprimer",
              cancelTitle: "Délier",
              cancelVariant: "outline-warning",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.deleteProductProvider({
                productProviderIds: [productProvider.id],
              });
              this.product.productProviders.splice(index, 1);
            } else if (value == false) {
              this.updateProductProvider({
                productProvider: { ...productProvider, productId: null },
                reload: false,
              });
              this.product.productProviders.splice(index, 1);
            }
          });
      } else {
        this.product.productProviders.splice(index, 1);
      }
    },
    actionDegressiveSaleRateArray(value) {
      if (value) {
        this.product.degressiveSalesRates = [
          {
            quantityMin: 0,
            quantityMax: 2,
            price: this.product.priceHT,
            discount: this.product.discount,
            productId: null,
            marginRate: 0,
            grossMarginHT: 0,
          },
          {
            quantityMin: 2.01,
            quantityMax: null,
            price: 0,
            discount: 0,
            productId: null,
            marginRate: 0,
            grossMarginHT: 0,
          },
        ];
        this.product.priceHT = 0;
        this.product.marginRate = 0;
        this.product.grossMarginHT = 0;
      } else {
        this.product.priceHT = this.product.degressiveSalesRates[0].price;
        this.product.degressiveSalesRates = [];
      }
      if (this.checkFunctionUser(34, "all")) {
        this.changeMargin();
      } else {
        this.changeMarginWithoutPurchaseModule();
      }
    },
    addDegressiveSaleRate() {
      let number =
        this.product.degressiveSalesRates[
          this.product.degressiveSalesRates.length - 1
        ].quantityMin + 2.99;
      this.product.degressiveSalesRates[
        this.product.degressiveSalesRates.length - 1
      ].quantityMax = number;
      this.product.degressiveSalesRates.push({
        quantityMin: number + 0.01,
        quantityMax: null,
        price: 0,
        discount: 0,
        ecoContribution: 0,
        marginRate: 0,
        grossMarginHT: 0,
      });
      this.key++;
    },
    changeQuantityMax(index) {
      for (
        let i = index + 1;
        i < this.product.degressiveSalesRates.length;
        i++
      ) {
        let element = this.product.degressiveSalesRates[i];
        if (i == this.product.degressiveSalesRates.length - 1) {
          element.quantityMin =
            Number(this.product.degressiveSalesRates[i - 1].quantityMax) + 0.01;
          element.quantityMax = null;
        } else {
          let number = Number(
            this.product.degressiveSalesRates[i - 1].quantityMax
          );
          element.quantityMin = number + 0.01;
          element.quantityMax = number + 3;
        }
      }
      this.key++;
    },
    deleteDegressiveSaleRateLocal(index, element) {
      if (index == 0) {
        this.product.degressiveSalesRates[index + 1].quantityMin = 0;
      } else if (index == this.product.degressiveSalesRates.length - 1) {
        this.product.degressiveSalesRates[index - 1].quantityMax = null;
      } else {
        this.product.degressiveSalesRates[index + 1].quantityMin =
          element.quantityMin;
      }
      if (element.id) {
        this.deleteDegressiveSaleRates({ degressiveSaleRateIds: [element.id] });
      }
      this.product.degressiveSalesRates.splice(index, 1);
      this.key++;
    },
    actiondegressivePurchaseRateArray(value, index) {
      if (value) {
        this.product.productProviders[index].degressivePurchaseRates = [
          {
            quantityMin: 0,
            quantityMax: 2,
            price: this.product.productProviders[index].netPrice,
            discount: this.product.productProviders[index].discount,
            ecoContribution: 0,
            marginRate: this.product.productProviders[index].marginRate,
            grossMarginHT: this.product.productProviders[index].grossMarginHT,
          },
          {
            quantityMin: 2.01,
            quantityMax: null,
            price: 0,
            discount: 0,
            ecoContribution: 0,
            marginRate: 0,
            grossMarginHT: 0,
          },
        ];
        this.product.productProviders[index].netPrice = 0;
        this.product.productProviders[index].marginRate = 0;
        this.product.productProviders[index].grossMarginHT = 0;
      } else {
        this.product.productProviders[index].netPrice =
          this.product.productProviders[index].degressivePurchaseRates[0].price;
        this.product.productProviders[index].marginRate =
          this.product.productProviders[
            index
          ].degressivePurchaseRates[0].marginRate;
        this.product.productProviders[index].grossMarginHT =
          this.product.productProviders[
            index
          ].degressivePurchaseRates[0].grossMarginHT;
        this.product.productProviders[index].degressivePurchaseRates = [];
      }
      this.changeMargin();
      this.key++;
    },
    addDegressivePurchaseRate(index) {
      let number =
        this.product.productProviders[index].degressivePurchaseRates[
          this.product.productProviders[index].degressivePurchaseRates.length -
            1
        ].quantityMin + 2.99;
      this.product.productProviders[index].degressivePurchaseRates[
        this.product.productProviders[index].degressivePurchaseRates.length - 1
      ].quantityMax = number;
      this.product.productProviders[index].degressivePurchaseRates.push({
        quantityMin: number + 0.01,
        quantityMax: null,
        price: 0,
        discount: 0,
        ecoContribution: 0,
        marginRate: 0,
        grossMarginHT: 0,
      });
      this.key++;
    },
    changeQuantityMaxProvider(
      indexProductProvider,
      indexDegressivePurchaseRate
    ) {
      for (
        let i = indexDegressivePurchaseRate + 1;
        i <
        this.product.productProviders[indexProductProvider]
          .degressivePurchaseRates.length;
        i++
      ) {
        let element =
          this.product.productProviders[indexProductProvider]
            .degressivePurchaseRates[i];
        if (
          i ==
          this.product.productProviders[indexProductProvider]
            .degressivePurchaseRates.length -
            1
        ) {
          element.quantityMin =
            Number(
              this.product.productProviders[indexProductProvider]
                .degressivePurchaseRates[i - 1].quantityMax
            ) + 0.01;
          element.quantityMax = null;
        } else {
          let number = Number(
            this.product.productProviders[indexProductProvider]
              .degressivePurchaseRates[i - 1].quantityMax
          );
          element.quantityMin = number + 0.01;
          element.quantityMax = number + 3;
        }
      }
      this.key++;
    },
    deleteDegressivePurchaseRateLocal(indexParent, index, element) {
      if (index == 0) {
        this.product.productProviders[indexParent].degressivePurchaseRates[
          index + 1
        ].quantityMin = 0;
      } else if (
        index ==
        this.product.productProviders[indexParent].degressivePurchaseRates
          .length -
          1
      ) {
        this.product.productProviders[indexParent].degressivePurchaseRates[
          index - 1
        ].quantityMax = null;
      } else {
        this.product.productProviders[indexParent].degressivePurchaseRates[
          index + 1
        ].quantityMin = element.quantityMin;
      }
      if (element.id) {
        this.deleteDegressivePurchaseRates({
          degressivePurchaseRateIds: [element.id],
        });
      }
      this.product.productProviders[indexParent].degressivePurchaseRates.splice(
        index,
        1
      );
      this.key++;
    },
    newProvider() {
      this.$router.push({ path: "/directory/new-company/fournisseurs/4" });
    },
  },
  components: {
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadioGroup,
    quillEditor,
    Treeselect,
    vSelect,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BTable,
    ProductWorkElements,
    vueDropzone: vue2Dropzone,
  },
};
</script>
<style scoped>
.radio-form-product.demo-inline-spacing > * {
  margin-top: 10px !important;
}

.floating-item-left {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.floating-item-right {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.floating-item-right-label {
  position: absolute;
  right: -20px;
  top: 50%;
}

.header-supplier-price {
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #ccc;
  margin-top: 10px !important;
  border-radius: 3px;
}

.form-control:disabled {
  background-color: #efefef !important;
  border: 1px solid #d8d6de !important;
}

.sidebar-closed,
.sidebar-closed .title span {
  transition-delay: 0.5s;
  transition: all 0.5s ease-in-out;
}

.sidebar-closed .title span {
  font-size: 0;
}
.sidebar-closed .title .vs-divider {
  margin: 0 !important;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.sidebar-closed .close-btn span {
  transform: rotate(180deg);
}

.sidebar-closed .close-btn {
  position: relative;
}

.head {
  padding-top: 1.2rem;
  & h3 {
    font-size: 1.2rem;
  }
}

.sidebar-closed .head {
  padding-top: 0;
}

.sidebar-closed .head,
.sidebar-closed .title {
  animation: fadeOut 0.5s ease-in-out forwards;
}

.sidebar-closed {
  width: 52px !important;
  min-width: 52px !important;
  max-width: 52px !important;
}

#content-wrapper {
  transition: all 0.5s ease-in-out;
}

#content-wrapper.sidebar-is-closed {
  width: calc(100% - 52px) !important;
}

#nav-sticky-vertical,
.title span,
.head,
.title,
.title .vs-divider {
  transition: all 0.5s ease-in-out;
}

@keyframes fadeOut {
  from {
    max-height: 100%;
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
  to {
    max-height: 0%;
    width: 0%;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
