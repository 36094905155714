<template lang="pug">
  #editProductWorkElements
    .document-table
      div
        .document-head.bg-primary
          .product-line.builder
            div(v-for="(key, i) in Object.keys(workElementHeader)" :key="i" :class="workElementHeader[key].class" :style="workElementHeader[key].style") {{workElementHeader[key].text}}  
      draggable(v-if="getWorkElementDetails.length" :list="getWorkElementDetails" handle=".handle")
        ProductWorkElementsDetails(v-for="(workLine, index) in getWorkElementDetails" :key="index" :line="workLine" :index="index" :product="product") 
      div(v-else)
        div.p-1.text-center.text-muted
          | Cliquez sur un des boutons ci-dessous pour ajouter des lignes à votre ouvrage
      .pt-1
        .toolbar.w-100.d-flex
          b-button.mr-1.button-tools(@click="newElement({productType: 5, unitId: 2})" variant="outline-primary") 
            div(v-if="isLoadingLine5")
              b-spinner.mr-1(small)
              | Chargement...
            div(v-else)
              feather-icon(icon="PlusIcon")
              span Main d'oeuvre
          b-button.mr-1.button-tools(@click="newElement({productType: 0, unitId : 1})" variant="outline-primary") 
            div(v-if="isLoadingLine0")
              b-spinner.mr-1(small)
              | Chargement...
            div(v-else)
              feather-icon(icon="PlusIcon")
              span Fourniture
          b-button.mr-1.button-tools(@click="newElement({productType: 2, unitId: 1})" variant="outline-primary") 
            div(v-if="isLoadingLine2")
              b-spinner.mr-1(small)
              | Chargement...
            div(v-else)
              feather-icon(icon="PlusIcon")
              span Matériel
          b-button.mr-1.button-tools(@click="newElement({productType: 1, unitId: 1})" variant="outline-primary") 
            div(v-if="isLoadingLine1")
              b-spinner.mr-1(small)
              | Chargement...
            div(v-else)
              feather-icon(icon="PlusIcon")
              span Sous-traitance
          b-button.mr-1.button-tools(@click="newElement({productType: 6, unitId: 1})" variant="outline-primary") 
            div(v-if="isLoadingLine6")
              b-spinner.mr-1(small)
              | Chargement...
            div(v-else)
              feather-icon(icon="PlusIcon")
              span Autre 
          //- b-button.mr-1.button-tools(@click="newElement({productType: 4, unitId: 1})" variant="outline-primary") 
          //-   div(v-if="isLoadingLine4")
          //-     b-spinner.mr-1(small)
          //-     | Chargement...
          //-   div(v-else)
          //-     feather-icon(icon="PlusIcon")
          //-     span Texte
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
import ProductWorkElementsDetails from "@/components/catalog/formProduct/ProductWorkElementsDetails";
import { ProductDefaultProperties } from "@/types/api-orisis/models/ProductModel";

export default {
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      workElementList: [],
      isLoadingLine0: false,
      isLoadingLine1: false,
      isLoadingLine2: false,
      isLoadingLine4: false,
      isLoadingLine5: false,
      isLoadingLine6: false,
      ProductDefaultProperties,
    };
  },
  mounted() {
    this.isLoadingLine0 = false;
    this.isLoadingLine1 = false;
    this.isLoadingLine2 = false;
    this.isLoadingLine4 = false;
    this.isLoadingLine5 = false;
    this.isLoadingLine6 = false;
  },
  methods: {
    ...mapActions(["addWorkElement"]),
    async newElement(line) {
      if (line.productType == 0) this.isLoadingLine0 = true;
      if (line.productType == 1) this.isLoadingLine1 = true;
      if (line.productType == 2) this.isLoadingLine2 = true;
      if (line.productType == 4) this.isLoadingLine4 = true;
      if (line.productType == 5) this.isLoadingLine5 = true;
      if (line.productType == 6) this.isLoadingLine6 = true;

      let workline = this.ProductDefaultProperties({
        productType: line.productType,
        unitId: line.unitId,
      });
      await this.addWorkElement({ payload: workline });

      if (line.productType == 0) this.isLoadingLine0 = false;
      if (line.productType == 1) this.isLoadingLine1 = false;
      if (line.productType == 2) this.isLoadingLine2 = false;
      if (line.productType == 4) this.isLoadingLine4 = false;
      if (line.productType == 5) this.isLoadingLine5 = false;
      if (line.productType == 6) this.isLoadingLine6 = false;
    },
  },
  computed: {
    ...mapGetters(["getWorkElementDetails"]),
    workElementHeader: {
      get() {
        return this.$store.getters.getWorkElementHeader;
      },
    },
  },
  components: {
    draggable,
    ProductWorkElementsDetails,
  },
};
</script>

<style>
feather-icon {
  cursor: pointer;
}

.wpx200 {
  width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.elementRow {
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #ccc;
  align-items: center;
  margin-top: 20px !important;
}
</style>
