<template lang="pug">
  span
    span(class='label')
</template>

<script>
export default {
  data() {
    return {
      item: null,
      data: null,
      dataSource: null,
    };
  },
};
</script>
