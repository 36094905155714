<template lang="pug">
  .document-line(@mouseover="edit = true, mouseOn = true" @mouseleave="edit=false, mouseOn=false")
      .product-line.builder
          //- input(class="w-100" :ref="'fakeInputProductWorkElementDetails'+index" style="width: 0px !important; position: absolute; display: block")
          div.m-0(v-for="(key, i) in Object.keys(workElementHeader)" :key="i" :class="workElementHeader[key].class" :style="workElementHeader[key].style") 
              div(v-if="workElementHeader[key].inputType == 1" class="d-flex flex-row align-items-center")
                div.type-line.no-printting.material-icons-outlined.text-danger(v-if="referenceOfNewProductProviderWorkElementAlreadyExists(line) || referenceOfNewProductWorkElementAlreadyExists({line, index})" v-b-tooltip.hover.v-danger title="Référence existante" style="font-size:14px; left: 0 ; position: relative") error
                div.type-line.no-printting.material-icons-outlined.text-danger(v-else-if="!line.reference" v-b-tooltip.hover.v-danger title="Référence obligatoire" style="font-size:14px; left: 0 ; position: relative") error
                div.content-span-document-detail(v-if="!workElementHeader[key].editable")
                    span {{workElementHeader[key].prefix }}
                    span {{workElementHeader[key].numberToFixed ? (line[key]).toFixed(workElementHeader[key].numberToFixed) : line[key]}}
                    span {{workElementHeader[key].unit}}
                input.w-100(:disabled="line.id !== 0 && key === 'reference'" v-else @focus="editFocus=true" @blur="blurFunction(false)" :class="(line.id && key === 'reference') && 'disabled'"  v-model="line[key]")
              div(v-else-if="workElementHeader[key].inputType == 2") 
                  div.content-span-document-detail(v-if="!workElementHeader[key].editable || (line.id !== 0 && key !== 'quantity')")
                    span {{workElementHeader[key].prefix }} 
                    span {{workElementHeader[key].numberToFixed ? line[key].toFixed(workElementHeader[key].numberToFixed) : line[key]}}
                    span {{workElementHeader[key].unit}}
                  input.w-100(v-else @focus="changeEditFocus(true)" @blur="changePricies(line,key)" type="number" min='0' :class="edit ? 'edit' : 'no-edit'" v-model="line[key]" :style="marginNegativeStyle(key)")
              div(v-else-if="workElementHeader[key].inputType == 10") 
                  select.form-select.custom-select(@focus="editFocus=true" @blur="blurFunction(false)" :class="edit ? 'edit' : 'no-edit'" v-model="line[key]" v-if="line.id === 0")
                      option(v-for='(item, index) in workElementHeader[key].choice' :value="item.id")  {{ item.label }}
                  div.content-span-document-detail(v-else)
                    span {{ labelUnit(line[key]) }}
              div(v-else-if="workElementHeader[key].inputType == 23") 
                  //- MarginCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="key" :workElementHeader="workElementHeader" :edit="edit" :editFocus="editFocus")
              div(v-else-if="workElementHeader[key].inputType == 24") 
                .dropdown.search-select(v-if="line.id === 0")
                      quill-editor(@focus="focusFunction(true)" @blur="blurFunction(false)"  :class="[(editFocus && mouseOn) || (searchCatalog && mouseOn) ? 'edit' : 'no-edit', editFocus? 'focus' : '']" v-model="line[key]" :options="editorOption")
                      span.feather-search-catalog(class='material-icons' :class="editFocus && mouseOn  ? 'edit' : ''" @click="activeSearchCatalog()") search
                      .select-results.dropdown-content(v-show="searchCatalog && mouseOn")
                        input(class="w-100 edit" name="keywordsCatalog" ref="keywordsCatalog" placeholder="Saisissez vos mots-clés ou une référence pour rechercher dans le catalogue" v-model="keywordsCatalog") 
                        div(v-if="keywordsCatalog.length > 1")
                          div( v-for="(item, key) in productsListByTypeLine(line, catalogSortedByProductType, keywordsCatalog)")
                            span.select-title {{ key }}
                            div(v-if="item.length > 0")
                              .d-flex.align-items-end(v-for="(product, index) in item")
                                .d-flex.p-50.cursor-pointer.select-catalogue-line(style="flex:1" @click="affectValue(product)")
                                  div(style="width:100px") {{ product.reference }}
                                  div(style="flex:1")
                                    span(v-html="product.description")        
                                  div.text-right(style="width:100px")
                                    span {{ formatCurrency(product.priceHT) }}  
                            div.text-center(v-else)
                              span
                                | Aucun article trouvé de type {{ key.toLowerCase() }}
                div.content-span-document-detail(v-else)
                  span(v-html="line[key]")    
          vs-dropdown.more-actions(vs-trigger-click)
            span.handle.cursor-pointer(class='material-icons') expand_more
            vs-dropdown-menu
              vs-dropdown-item(@click="duplicateWorkElement(line)")
                | Dupliquer
              vs-dropdown-item(@click="removeWorkElement({index:index, product:line})")
                | Supprimer
  </template>

<script>
import MarginCol from "@/components/invoice/builder/table/cols/MarginCol.vue";
import NumberCol from "@/components/invoice/builder/table/cols/NumberCol.vue";
import SelectCatalog from "@/components/invoice/builder/table/cols/SelectCatalog.vue";
import SelectCol from "@/components/invoice/builder/table/cols/SelectCol.vue";
import TextCol from "@/components/invoice/builder/table/cols/TextCol.vue";
import { productsListByTypeLine } from "@/types/api-orisis/library/DetailDocumentOperations";

import {
  formatNumber,
  formatCurrency,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { remove } from "@syncfusion/ej2-base";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      textAreaFocus: false,

      edit: false,
      mouseOn: false,
      editFocus: false,

      searchCatalog: false,
      keywordsCatalog: "",
      productsListByTypeLine,
      lastKeyChange: "",
      editorOption: {
        placeholder: "Description...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "catalogSortedByProductType",
      "getWorkElementDetails",
      "unitsList",
    ]),
    workElementHeader: {
      get() {
        return this.$store.getters.getWorkElementHeader;
      },
    },
  },
  created() {
    this.searchCatalog = false;
  },
  mounted() {
    this.searchCatalog = false;
  },
  props: {
    product: {
      type: Object,
    },
    line: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },

  watch: {
    edit(val) {
      if (
        val == false &&
        (this.editFocus == true || this.searchCatalog == true)
      ) {
        this.$refs["fakeInputProductWorkElementDetails" + this.index]?.focus({
          preventScroll: true,
        });
        this.searchCatalog = false;
        this.keywordsCatalog = "";
      } else if (val == false) {
        this.searchCatalog = false;
        this.keywordsCatalog = "";
      }
    },
  },
  methods: {
    formatNumber,
    formatCurrency,
    ...mapActions(["deleteWorkElement"]),
    labelUnit(unitId) {
      return this.unitsList.find((unit) => unit.id === unitId).label;
    },
    changeEditFocus(val) {
      this.editFocus = val;
    },
    activeSearchCatalog() {
      this.searchCatalog = true;
      let _this = this;
      this.$nextTick(() => {
        this.$refs.keywordsCatalog[0].focus({ preventScroll: true });
      });
    },

    focusFunction(res) {
      this.editFocus = res;
    },
    blurFunction(res) {
      this.editFocus = res;
      if (
        this.getWorkElementDetails.every(
          (item) => item.description && item.reference
        )
      ) {
        this.$store.commit("SET_WORK_ELEMENT_VALIDATE", true);
      } else {
        this.$store.commit("SET_WORK_ELEMENT_VALIDATE", false);
      }
    },
    marginNegativeStyle(key) {
      if (key == "dryDisbursed" || key == "priceHT" || key == "marginRate") {
        if (
          this.line.priceHT &&
          this.line.dryDisbursed &&
          this.line.priceHT - this.line.dryDisbursed < 0
        ) {
          return "color:red !important; border-color:red !important";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    changePricies(line, key) {
      this.editFocus = true;
      if (key == "dryDisbursed") {
        // On calcule la marge
        let marginPrice = line.priceHT - line.dryDisbursed;
        line.grossMarginHT = formatNumber(marginPrice);
        let marginRate =
          line.priceHT == 0 ? 0 : (line.grossMarginHT / line.priceHT) * 100;
        line.marginRate = formatNumber(marginRate);
        line.priceHT =
          line.marginRate == 0 ? line.dryDisbursed : formatNumber(line.priceHT);
        this.lastKeyChange = key;
      } else if (key == "priceHT") {
        // On calcule la marge
        let marginPrice = line.priceHT - line.dryDisbursed;
        line.grossMarginHT = formatNumber(marginPrice);
        let marginRate =
          line.dryDisbursed == 0
            ? 0
            : (line.grossMarginHT / line.priceHT) * 100;
        line.marginRate = formatNumber(marginRate);
        line.dryDisbursed =
          line.marginRate == 0 ? line.priceHT : formatNumber(line.dryDisbursed);
        this.lastKeyChange = key;
      } else if (key == "marginRate") {
        if (this.lastKeyChange === "dryDisbursed") {
          line.priceHT = formatNumber(
            line.dryDisbursed * (1 + line.marginRate / 100)
          );
        } else if (this.lastKeyChange === "priceHT") {
          line.dryDisbursed = formatNumber(
            line.priceHT / (1 + line.marginRate / 100)
          );
        }
      }

      let totalPriceHt = 0;
      let totalDryDisbursed = 0;
      for (let index = 0; index < this.getWorkElementDetails.length; index++) {
        const element = this.getWorkElementDetails[index];
        totalPriceHt += formatNumber(element.priceHT * element.quantity);
        totalDryDisbursed += formatNumber(
          element.dryDisbursed * element.quantity
        );
      }
      this.product.priceHT = formatNumber(this.product.quantity * totalPriceHt);
      this.product.dryDisbursed = formatNumber(totalDryDisbursed);
      this.product.grossMarginHT = formatNumber(
        this.product.priceHT - this.product.dryDisbursed
      );
      this.product.marginRate = formatNumber(
        this.product.priceHT == 0
          ? 0
          : (this.product.grossMarginHT / this.product.priceHT) * 100
      );

      // On met à jour le total
      line.total = formatNumber(line.priceHT * line.quantity);
    },
    checkIfArraysAreEmpty(object) {
      let limit = Object.keys(object).length;
      let count = 0;
      for (let i = 0; i < Object.keys(object).length; i++) {
        const element = Object.keys(object)[i];
        if (object[element].length > 0) {
          return true;
        }
      }
      return false;
    },
    affectValue(val) {
      this.line["id"] = val.id && val.id;
      this.line["description"] = val.description ? val.description : val.label;
      this.line["label"] = val.label ? val.label : null;
      this.line["reference"] = val.reference ? val.reference : null;
      this.line["quantity"] = val.quantity ? val.quantity : 1;
      this.line["unitId"] = val.unit.id ? val.unit.id : this.line["unitId"];
      this.line["priceHT"] = val.priceHT ? val.priceHT : this.line["priceHT"];
      this.line["marginRate"] = val.marginRate
        ? val.marginRate
        : this.line["marginRate"];
      this.line["grossMarginHT"] = val.grossMarginHT
        ? val.grossMarginHT
        : this.line["grossMarginHT"];
      this.line["dryDisbursed"] = val.dryDisbursed
        ? val.dryDisbursed
        : this.line["dryDisbursed"];
      this.line["total"] = val.priceHT
        ? Math.round(val.quantity * val.priceHT * 100) / 100
        : this.line["total"];
      switch (val.productType) {
        case 0:
          this.line["type"] = 4;
          break;
        case 1:
          this.line["type"] = 7;
          break;
        case 2:
          this.line["type"] = 8;
          break;
        case 3:
          this.line["type"] = 6;
          break;
        case 4:
          this.line["type"] = 1;
          break;
        case 5:
          this.line["type"] = 5;
          break;
        case 6:
          this.line["type"] = 9;
          break;
        default:
          this.line["type"] = 9;
          break;
      }
      this.textAreaFocus = false;
      this.keywordsCatalog = "";
      this.searchCatalog = false;

      // Mettre à jour les détails tarifaire
      let totalPriceHt = 0;
      let totalDryDisbursed = 0;
      for (let index = 0; index < this.getWorkElementDetails.length; index++) {
        const element = this.getWorkElementDetails[index];
        totalPriceHt += formatNumber(element.priceHT * element.quantity);
        totalDryDisbursed += formatNumber(
          element.dryDisbursed * element.quantity
        );
      }
      this.product.priceHT = formatNumber(this.product.quantity * totalPriceHt);
      this.product.dryDisbursed = formatNumber(totalDryDisbursed);
      this.product.grossMarginHT = formatNumber(
        this.product.priceHT - this.product.dryDisbursed
      );
      this.product.marginRate = formatNumber(
        this.product.priceHT == 0
          ? 0
          : (this.product.grossMarginHT / this.product.priceHT) * 100
      );
    },
    duplicateWorkElement(product) {
      let workline = JSON.parse(JSON.stringify(product));
      this.$store.dispatch("addWorkElement", { payload: workline });
    },
    referenceOfNewProductProviderWorkElementAlreadyExists(line) {
      if (
        this.$store.getters.productProvidersList.some(
          (item) => line.reference === item.reference
        )
      ) {
        return true; // Une référence en double est trouvée
      }
    },
    referenceOfNewProductWorkElementAlreadyExists({ line, index }) {
      let workElementDetails = structuredClone(this.getWorkElementDetails);
      workElementDetails.splice(index, 1);
      if (
        workElementDetails.some((item) => line.reference === item.reference)
      ) {
        return true; // Une référence en double est trouvée
      }
    },
    removeWorkElement({ index }) {
      let workElementDetails = structuredClone(this.getWorkElementDetails);
      workElementDetails.splice(index, 1);
      this.$store.commit("SET_WORK_ELEMENT_DETAILS", workElementDetails);
    },
  },
  components: {
    SelectCol,
    TextCol,
    NumberCol,
    MarginCol,
    SelectCatalog,
    quillEditor,
  },
};
</script>
